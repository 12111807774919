var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('v-container', [_c('div', {
    staticClass: "title-wrap title-wrap--line"
  }, [_c('h2', {
    staticClass: "font-42"
  }, [_vm._v("문의 및 제휴")])]), _c('form-input', {
    attrs: {
      "code": "inquiry-partner",
      "skin": "inquiry-partner"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }