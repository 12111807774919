<template>
<div>
    <slot />
</div>
</template>

<script>
export default {
    props: {
        isMain: { type: Boolean, default: true },
    },
    components: {
    },
    data(){
        return {
        }
    },
    mounted() {
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작
    },
    methods: {
    },
};
</script>